import { gql } from 'apollo-boost'

export default {
  GET_SELLS: gql`
        query getSells {
          getSells{
            delivery
            index
            out
            note
            _id
            state
            createdAt
          user {
            name
          }
          client {
            name
            phone
            address
          }
          payment
          total
          products {
            product {
              name
              price
              weight{
                name
                amount
                unity
              }
            }
            amount
          }
          wrappers {
            wrapper {
              name
              price
              alias
              weight{
                name
                amount
                unity
              }
            }
            ingredients {
              name
              alias
              weight{
                name
                amount
                unity
              }
            }
          }
          promotions {
            promotion {
              name
		          selected
              price
              wrappers{
                wrapper{
                  name
                  alias
                  weight{
                    name
                    amount
                    unity
                  }
                }
              }
              products{
                product{
                  name
                  weight{
                    name
                    amount
                    unity
                  }
                }
              }
            }
            ingredients {
              wrapper {
                _id
                name  
                alias        
              }
              ingredients {
                name
                alias
                weight{
                  name
                  amount
                  unity
                }
              }
            }
          }
        }
        }
    `,
  ADD_SELL: gql`
        mutation addSell($client: ObjectId!, $promotions: [PromotionSellInput!]!, $wrappers: [WrapperSellInput!]!, $products: [ProductSellInput!]!, $total: Int!, $payment: String!, $delivery: Boolean!, $user: ObjectId!, $out: Date, $note: String, $discount: Int, $tip: TipInput){
            addSell(input: { client: $client, promotions: $promotions, wrappers: $wrappers, products: $products, total: $total, payment: $payment, delivery:$delivery, user: $user, out: $out, note: $note, discount: $discount, tip: $tip }){
              tip{
                type
                amount
              }
              discount
              delivery
              index
              out
              note
              _id
              state
              createdAt
              user {
                name
              }
              client {
                name
                phone
                address
              }
              payment
              total
              products {
                product {
                  name
		              price
                  weight{
                    name
                    amount
                    unity
                  }
                }
                amount
              }
              wrappers {
                wrapper {
                  name
                  alias
		              price
                  weight{
                    name
                    amount
                    unity
                  }
                }
                ingredients {
                  name
                  alias
                  weight{
                    name
                    amount
                    unity
                  }
                }
              }
              promotions{
                promotion{
                  name
		              price
                  selected
                  wrappers{
                    wrapper{
                      name
                      alias
                      price
                      weight{
                        name
                        amount
                        unity
                      }
                    }
                  }
                  products{
                    product{
                      name
                      price
                      weight{
                        name
                        amount
                        unity
                      }
                    }
                  }
                }
                ingredients {
                  wrapper {
                    _id
                    name  
                    alias        
                  }
                  ingredients {
                    name
                    alias
                    weight{
                      name
                      amount
                      unity
                    }
                  }
                }
              }
            }
        }    
    `,
  MODIFY_SELL: gql`
      mutation modifySell($_id: ObjectId!, $state: String!, $tip: TipInput, $payment: String){
          modifySell(input: {_id: $_id, state: $state, tip: $tip, payment: $payment})
      }
  `,
  GET_SELLS_RANGE_REPORT: gql`
  query getSellsInRange($from: Date!, $to: Date!, $format: String!){
    getSellsInRange(input:{from:$from, to: $to, format: $format}){
      date
      total
      transactions
      transfer
      detailTransfers
      discounts
      totalDiscount
      debit
      credit
      cash
      initialIndex
      tip{
          cash
          transfer
          transbank
        }
    }
  }
`,
  GET_SELLS_RANGE_DASHBOARD: gql`
    query getSellsInRange($from: Date!, $to: Date!, $format: String!){
      getSellsInRange(input:{from:$from, to: $to, format: $format}){
        total
        transactions
        transfer
        discounts
        totalDiscount
        debit
        credit
        tip{
          cash
          transfer
          transbank
        }
        cash
        rappi
        check
        weight{
          name
          weight
          unity
        }
      }
    }
  `,
  GET_SELLS_RANGE_BALANCING: gql`
  query getSellsInRange($from: Date!, $to: Date!, $format: String!){
    getSellsInRange(input:{from:$from, to: $to, format: $format}){
      total
      transfer
      debit
      credit
      cash
      rappi
      check
      discounts
      totalDiscount
      tip{
        cash
        transfer
        transbank
      }
    }
  }
`,
  GET_LAST_MONTHS: gql`
    query getLastMonthsSells{
      getLastMonthsSells{
        month
        total
      }
    }
  `,
  GET_SELLS_PAGINATION: gql`
    query getSellPagination($last:Int!, $limit: Int!){
      getSellPagination(input: {last:$last, limit: $limit}){
        discount
        delivery
        index
        out
        note
        _id
        state
        createdAt
        tip{
          amount
          type
        }
        user {
          name
        }
        client {
          name
          phone
          address
        }
        payment
        total
        products {
          product {
            name
            price
            weight{
              name
              amount
              unity
            }
          }
          amount
        }
        wrappers {
          wrapper {
            name
            alias
            price
            weight{
              name
              amount
              unity
            }
          }
          ingredients {
            name
            alias
            weight{
              name
              amount
              unity
            }
          }
        }
        promotions{
          promotion{
            name
		        selected
            price
            wrappers{
              wrapper{
                name
                alias
                weight{
                  name
                  amount
                  unity
                }
              }
            }
            products{
              product{
                name
                weight{
                  name
                  amount
                  unity
                }
              }
            }
          }
          ingredients {
            wrapper {
              _id
              name  
              alias        
            }
            ingredients {
              name
              alias
              weight{
                name
                amount
                unity
              }
            }
          }
        }
      }
    }
  `,
  GET_SELLS_FILTERED: gql`
    query getSellsFiltered($_id: ObjectId){
      getSellsFiltered(_id:$_id){
        discount
        delivery
        index
        out
        note
        tip{
          type
          amount
        }
        _id
        state
        createdAt
        user {
          name
        }
        client {
          name
          phone
          address
        }
        payment
        total
        products {
          product {
            name
            price
            weight{
              name
              amount
              unity
            }
          }
          amount
        }
        wrappers {
          wrapper {
            name
            alias
            price
            weight{
              name
              amount
              unity
            }
          }
          ingredients {
            name
            alias
            weight{
              name
              amount
              unity
            }
          }
        }
        promotions{
          promotion{
            name
		        selected
            price
            wrappers{
              wrapper{
                name
                alias
                weight{
                  name
                  amount
                  unity
                }
              }
            }
            products{
              product{
                name
                price
                weight{
                  name
                  amount
                  unity
                }
              }
            }
          }
          ingredients {
            wrapper {
              _id
              name  
              alias        
            }
            ingredients {
              name
              alias
              weight{
                name
                amount
                unity
              }
            }
          }
        }
      }
    }
  `,
  GET_SELLS_FILTERED_INDEX: gql`
  query getSellsFilteredIndex($index: Int){
    getSellsFilteredIndex(index:$index){
      discount
      delivery
      index
      out
      note
      tip{
        type
        amount
      }
      _id
      state
      createdAt
      user {
        name
      }
      client {
        name
        phone
        address
      }
      payment
      total
      products {
        product {
          name
          price
          weight{
            name
            amount
            unity
          }
        }
        amount
      }
      wrappers {
        wrapper {
          name
          alias
          price
          weight{
            name
            amount
            unity
          }
        }
        ingredients {
          name
          alias
          weight{
            name
            amount
            unity
          }
        }
      }
      promotions{
        promotion{
          name
          selected
          price
          wrappers{
            wrapper{
              name
              alias
              weight{
                name
                amount
                unity
              }
            }
          }
          products{
            product{
              name
              weight{
                name
                amount
                unity
              }
            }
          }
        }
        ingredients {
          wrapper {
            _id
            name  
            alias        
          }
          ingredients {
            name
            alias
            weight{
              name
              amount
              unity
            }
          }
        }
      }
    }
  }
`
}
